import React, { Component } from 'react';
import { Flex } from 'rebass';
import Img from '../styles/Img';
import Box from '../styles/Box';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Container from '../styles/Container';
import Heading from '../styles/Heading';
import { padding } from '../../theme';

const BannerHome = styled.div`
  min-height: 564px;
  height: 62.7vh;
  background: 'base';
  text-decoration: none;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query Hero {
        contentfulAboutPage(live: { eq: true }) {
          id
          heroHeadline
          heroImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Container py={padding}>
          <Flex flexWrap="wrap" mx="auto" pt="20px">
            <Box width={1}>
              <Heading as="h3" fontSize={[4, 5, 6]} my="50px" color="black">
                {data.contentfulAboutPage.heroHeadline.toUpperCase()}
              </Heading>
            </Box>

            <Box width={[1]} pt="40px">
              <Img fluid={data.contentfulAboutPage.heroImage.fluid} />
            </Box>
          </Flex>
        </Container>
      </div>
    )}
  />
);
