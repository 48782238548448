import React from 'react';
import Page from '../components/Page';
import Container from '../components/styles/Container';
import Hero from '../components/about/Hero';
import Mission from '../components/about/Mission';
import ThreeDads from '../components/about/ThreeDads';

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;
    // const { title } = this.props.data.allContentfulArticles;
    // const searchTerm = this.props.location.search.replace('?', '');
    return (
      <div>
        <Page>
          <Hero />
          <Container />
          <Container>
            <Mission />
          </Container>

          <Container>
            <ThreeDads />
          </Container>
        </Page>
      </div>
    );
  }
}

export default AboutPage;
